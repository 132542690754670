/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { gql } from '@apollo/client';
import { Grid } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import Wine from '../Wine';

export default function ExploreNewReleases({ wines, ...rest }) {
  const newWines = wines.filter(({ newFor2021 }) => newFor2021 === 'yes');
  return (
    <Grid width={170} height={240} gap={3} {...rest}>
      {newWines.map((wine) => (
        <Wine key={`${wine.registrationId}${wine.productId}`} wine={wine} />
      ))}
    </Grid>
  );
}

export const fragment = graphql`
  fragment ExploreNewReleases on Bottlebooks_RegisteredProduct {
    registrationId
    productId
    newFor2021: customFieldText(key: "thisIsANewProductForTheUkIn2021")
    ...Wine
  }
`;
ExploreNewReleases.fragment = gql`
  fragment ExploreNewReleases on RegisteredProduct {
    registrationId
    brandId
    newFor2021: customFieldText(key: "thisIsANewProductForTheUkIn2021")
    ...Wine
  }
  ${Wine.fragment}
`;
