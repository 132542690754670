/** @jsxRuntime classic */
/** @jsx jsx */
import { Container, Link, Text } from '@bottlebooks/gatsby-theme-base';
import EventBanner from '@bottlebooks/gatsby-theme-event/src/components/Event/EventBanner';
import SiteSearchSection from '@bottlebooks/gatsby-theme-event/src/components/LandingPage/SiteSearchSection';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import { LinkProvider } from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import ExploreNewReleases from '../../components/ExploreWines/ExploreNewReleases';
import ExploreWinesSwitcher from '../../components/ExploreWines/ExploreWinesSwitcher';

export default function NewReleasesPage({ pageContext, data }) {
  const { bottlebooks } = data;
  const { theme, importers, exporters } = bottlebooks;
  return (
    <LinkProvider value={pageContext.paths}>
      <Layout>
        <EventBanner event={theme} withOverlay={false} />
        <SiteSearchSection locale={pageContext.locale} />
        <Container>
          <ExploreWinesSwitcher>
            <Link to="/products">
              <Text>
                Or search by vegan, organic, on-trade and 10 other filters….
              </Text>
            </Link>
          </ExploreWinesSwitcher>
          <ExploreNewReleases
            wines={importers.wines.nodes.concat(exporters.wines.nodes)}
            sx={{ marginY: 3 }}
          />
        </Container>
      </Layout>
    </LinkProvider>
  );
}

export const query = graphql`
  query NewReleasesPage($locale: Bottlebooks_ContentLocale!) {
    bottlebooks {
      theme: event(eventId: "6048b4376a7c621a467c85d5", locale: $locale) {
        name
        ...bb_EventBanner
      }
      importers: event(eventId: "6033a2d31d00c321389bf5f7", locale: $locale) {
        ...NewReleasesPage_Fragment
      }
      exporters: event(eventId: "603f54d910828b19f691ff46", locale: $locale) {
        ...NewReleasesPage_Fragment
      }
    }
  }
  fragment NewReleasesPage_Fragment on Bottlebooks_Event {
    wines: registeredProducts {
      nodes {
        ...ExploreNewReleases
      }
    }
  }
`;
