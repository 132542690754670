/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Link, Text } from '@bottlebooks/gatsby-theme-base';
import ProductImage from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductImage';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

export default function Wine({ wine, ...rest }) {
  const link = useLink();
  return (
    <Link
      fallback="div"
      to={link.product(wine)}
      sx={{
        transition: 'none',
        ':hover': { backgroundColor: 'primary', color: 'onPrimary' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
      {...rest}
    >
      <ProductImage
        product={wine?.product}
        sx={{ backgroundColor: 'transparent' }}
      />
      <Text sx={{ textAlign: 'center', paddingY: 2 }}>
        <Text>{wine.product?.name}</Text>
        <Text variant="small" sx={{ color: 'lightText', paddingTop: 1 }}>
          {wine.product?.producer?.name}
        </Text>
      </Text>
    </Link>
  );
}

export const fragment = graphql`
  fragment Wine on Bottlebooks_RegisteredProduct {
    product {
      bottleImage {
        fixed(width: 100, height: 150, crop: PAD, background: "#FFF0") {
          src
          srcSet
          width
          height
          base64
        }
      }

      ... on Bottlebooks_Wine {
        name
        producer {
          name
        }
        grapeVarieties {
          varietyName
        }
      }
    }
    ...useLink_bb_RegisteredProduct
  }
`;

Wine.fragment = gql`
  fragment Wine on RegisteredProduct {
    product {
      ... on Wine {
        name
        grapeVarieties {
          varietyName
        }
      }
    }
  }
`;
